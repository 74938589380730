import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/[locale]/(public)/signin/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/[locale]/(public)/signin/NewsButton.tsx");
;
import(/* webpackMode: "eager" */ "/build/app/[locale]/(public)/signin/styles.module.css");
;
import(/* webpackMode: "eager" */ "/build/assets/images/bg-minimal.jpg");
;
import(/* webpackMode: "eager" */ "/build/assets/images/mercedes-benz-data-dashboard-logo-zweizeilig.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/image-component.js");
