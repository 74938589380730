import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/[locale]/HydrationCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/[locale]/NextIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/[locale]/RootStoreInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/components/Toaster/index.tsx");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-sans\",\"src\":[{\"path\":\"../../assets/fonts/MBCorpoSText-Regular-Web.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/MBCorpoSText-Bold-Web.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"optional\"}],\"variableName\":\"mbFont\"}");
;
import(/* webpackMode: "eager" */ "/build/styles/globals.css");
